import React from "react";
import PrivacyPage from "../components/pages/privacy";

interface PrivacyProps {}

const PrivacyPolicy: React.FC<PrivacyProps> = () => {
  return <PrivacyPage />;
};

export default PrivacyPolicy;
