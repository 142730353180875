import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const PrivacyContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.privacy-container {
    min-width: 100vw;
    min-height: 100vh;
    & > .privacy-container-max-width {
      max-width: 800px;
      margin: 0 auto;
      padding: ${({ theme }) => theme.spacing(20, 10, 10, 10)};
      display: flex;
      flex-direction: column;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
      line-height: 1.5;

      & > h1 {
        font-family: "utopia-std-display";
        font-weight: normal;
        text-align: center;
      }
      & > p {
        font-family: "utopia-std-display";
        font-size: 14px;
        color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
      }
      & > .privacy-header-paragraphs {
        color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
      }
      & > .main-content-container {
        display: flex;
        flex-direction: column;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
        & > .content-container {
          display: flex;
          flex-direction: column;
          gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
          & > h2 {
            font-size: 18px;
          }
          & > .content-items-container {
            & > .single-item-container {
              color: ${({ theme: { palette } }: ThemeType) =>
                palette.p_black.text};
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;
