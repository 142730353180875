import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Box from "@mui/material/Box";
import Seo from "components/common/seo";
import { PrivacyContainer } from "./styles";
import { ContentType } from "./types";
import { PrismicRichText } from "@prismicio/react";

interface PrivacyPageProps {}

const PrivacyPage: React.FC<PrivacyPageProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicPrivacyPolicy {
        nodes {
          data {
            title {
              richText
            }
            last_updated(formatString: "MMMM DD, yyyy")
            first_paragraph {
              richText
            }
            second_paragraph {
              richText
            }
            third_paragraph {
              richText
            }
            body {
              ... on PrismicPrivacyPolicyDataBodyContentBlock {
                id
                primary {
                  content_title {
                    richText
                  }
                }
                items {
                  content_section_paragraph {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data;
  return (
    <>
      <Seo />

      <PrivacyContainer className="privacy-container">
        <Box className="privacy-container-max-width">
          <PrismicRichText field={document.title.richText} />
          <p>Last updated: {document.last_updated}</p>
          <Box className="privacy-header-paragraphs">
            <PrismicRichText field={document.first_paragraph.richText} />
            <PrismicRichText field={document.second_paragraph.richText} />
            <PrismicRichText field={document.third_paragraph.richText} />
          </Box>
          <Box className="main-content-container">
            {document.body.map((content: ContentType, index: number) => {
              return (
                <Box key={index} className="content-container">
                  <PrismicRichText
                    field={content.primary.content_title.richText}
                  />

                  <Box className="content-items-container">
                    {content.items.map((contentItem, index: number) => {
                      return (
                        <Box key={index} className="single-item-container">
                          <PrismicRichText
                            field={
                              contentItem.content_section_paragraph.richText
                            }
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </PrivacyContainer>
    </>
  );
};

export default PrivacyPage;
